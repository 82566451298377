import * as withGeographicsApiClient from "@unlockre/geographics-api-tools/dist/geographics-api-client";

import {auth0Client} from "@/utils/auth0";
import environmentVariables from "@/utils/environment/environment-variables";

const geographicsApiClient = withGeographicsApiClient.create(
  environmentVariables.GEOGRAPHICS_API_URL,
  auth0Client
);

export {geographicsApiClient};
