import * as withAuth0Client from "@unlockre/utils-auth0/dist/auth0-client";

import {auth0Client} from "@/utils/auth0/auth0-client";

const withAuth0Token = async (request: Request) => {
  const accessToken = await withAuth0Client.getAccessToken(auth0Client);

  request.headers.append("Authorization", `Bearer ${accessToken}`);

  return request;
};

export {withAuth0Token};
