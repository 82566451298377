import {buyBoxApiName} from "@unlockre/buy-box-api-tools/dist/buy-box-api-name";
import {geographicsApiName} from "@unlockre/geographics-api-tools/dist";
import {OpenApiClientsProvider} from "@unlockre/open-api-client/dist/open-api-clients-context";
import {organizationsApiName} from "@unlockre/organizations-api-tools/dist/organizations-api-name";
import {propertyAnalyzerApiName} from "@unlockre/property-analyzer-api-tools/dist/property-analyzer-api-name";
import type {ReactElement} from "react";

import {buyBoxApiClient} from "@/utils/api/buy-box/buy-box-api-client";
import {geographicsApiClient} from "@/utils/api/geographics";
import {organizationsApiClient} from "@/utils/api/organizations";
import {propertyAnalyzerApiClient} from "@/utils/api/property-analyzer/property-analyzer-api-client";
import {
  propertySearchApiClient,
  propertySearchApiName
} from "@/utils/api/property-search";

type Props = {
  children: ReactElement;
};

const apiClients = {
  [propertySearchApiName]: propertySearchApiClient,
  [organizationsApiName]: organizationsApiClient,
  [buyBoxApiName]: buyBoxApiClient,
  [propertyAnalyzerApiName]: propertyAnalyzerApiClient,
  [geographicsApiName]: geographicsApiClient
};

const ApiClientsProvider = ({children}: Props) => (
  <OpenApiClientsProvider value={apiClients}>{children}</OpenApiClientsProvider>
);

export {ApiClientsProvider};
