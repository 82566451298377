import * as withAuth0Client from "@unlockre/utils-auth0/dist/auth0-client";

import environmentVariables from "@/utils/environment/environment-variables";

const auth0Client = withAuth0Client.create({
  audience: environmentVariables.AUTH0_AUDIENCE,
  clientId: environmentVariables.AUTH0_CLIENT_ID,
  domain: environmentVariables.AUTH0_DOMAIN
});

export {auth0Client};
