import {css} from "styled-components";

const customScrollbar = css`
  ::-webkit-scrollbar-button {
    display: none;
  }

  /* Scrollbar Size */
  ::-webkit-scrollbar {
    height: 1rem;
    width: 0.5rem;
  }

  /* Scrollbar for horizontal scrolling */
  ::-webkit-scrollbar:horizontal {
    height: 0.5rem;
    width: 1rem;
  }

  /* Scrollbar Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px;
  }

  /* Scrollbar Thumb */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(124, 124, 124, 0.8);
    border-color: rgba(255, 255, 255, 1);
    border-radius: 9999px;
    border-width: 1px;
  }

  /* Scrollbar Thumb Hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(217, 217, 227, 1);
  }
`;

export {customScrollbar};
