/* eslint-disable prettier/prettier */

/**
 * @type Record<string, string>
 */
const dotEnvVars = {
  "BUY_BOX_API_URL": "https://buy-box-gw.whykeyway.com",
  "DATADOG_API_KEY": "ed0b77d0b8c9f13548490972debc75e9",
  "DATADOG_APP_KEY": "672809d1-a4d7-497a-9db0-b589d45c7dcf",
  "DEMOGRAPHICS_API_URL": "https://demographics-gw.whykeyway.com",
  "GEOGRAPHICS_API_URL": "https://geographics-gw.whykeyway.com",
  "PROPERTY_ASSETS_API_URL": "https://property-assets-gw.whykeyway.com",
  "GH_PAT": "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",
  "KEY_ASSIST_API_URL": "https://key-assist-gw.whykeyway.com",
  "NEXT_PUBLIC_AMPLITUDE_API_URL": "https://ysnb.whykeyway.com/2/httpapi",
  "NEXT_PUBLIC_APP_ENV": "production",
  "NEXT_PUBLIC_ASSET_MANAGER_APP_URL": "https://kos.whykeyway.com/asset-manager",
  "NEXT_PUBLIC_AUTH0_AUDIENCE": "https://keyway-api.whykeyway.com",
  "NEXT_PUBLIC_AUTH0_CLIENT_ID": "rSNYbABPTeOOqwxkZa36atuBXCktxpd1",
  "NEXT_PUBLIC_AUTH0_DOMAIN": "auth.whykeyway.com",
  "NEXT_PUBLIC_BASE_PATH": "/property-sage",
  "NEXT_PUBLIC_BUY_BOX_API_URL": "https://buy-box-gw.whykeyway.com",
  "NEXT_PUBLIC_GEOGRAPHICS_API_URL": "https://geographics-gw.whykeyway.com",
  "NEXT_PUBLIC_DATADOG_APPLICATION_ID": "685a3545-7740-4dcf-a76f-32e3806072c8",
  "NEXT_PUBLIC_DATADOG_CLIENT_TOKEN": "pub9da83b1c72fc3319e36019b502ffcf91",
  "NEXT_PUBLIC_DEAL_ROOM_ADMIN_APP_URL": "https://kos.whykeyway.com/deal-room-admin",
  "NEXT_PUBLIC_GOOGLE_MAPS_API_KEY": "AIzaSyCOdrjZYRKNwzAF0tU_06WlAu9eqihyQjU",
  "NEXT_PUBLIC_KEYBRAIN_APP_URL": "https://kos.whykeyway.com/keybrain",
  "NEXT_PUBLIC_KEYCOMPS_APP_URL": "https://kos.whykeyway.com/keycomps",
  "NEXT_PUBLIC_KEYDOCS_APP_URL": "https://kos.whykeyway.com/keydocs",
  "NEXT_PUBLIC_PROPERTY_ANALYZER_API_URL": "https://property-analyzer-gw.whykeyway.com",
  "NEXT_PUBLIC_PROPERTY_SAGE_APP_URL": "https://kos.whykeyway.com/property-sage",
  "NEXT_PUBLIC_PROPERTY_SEARCH_API_URL": "https://property-search-gw.whykeyway.com",
  "NEXT_PUBLIC_SENTRY_DSN": "https://98db2ad4c5844d7cbec32780e548a3fe@o1120092.ingest.sentry.io/4504453072224256",
  "NEXT_PUBLIC_SPLIT_IO_BROWSER_API_KEY": "7pll6aassgbuhoglp6encekbsm8dhfg0stp",
  "NEXT_PUBLIC_USERBACK_ACCESS_TOKEN": "A-xCrUlBM18ttryZ8qF8XYEBTuu",
  "NEXT_PUBLIC_ORGANIZATIONS_API_URL": "https://organizations-gw.whykeyway.com",
  "PROPERTY_SEARCH_API_URL": "https://property-search-gw.whykeyway.com",
  "DATA_GPT_API_URL": "https://data-gpt-api-fcrgmv73aq-uc.a.run.app",
  "DATA_GPT_API_KEY": "cb3ade2fcfd47b62e8ffa711ddf1e8cd6f161153da5d5800ced78d48b6dc7f7f",
  "SENTRY_AUTH_TOKEN": "7a603b5f9c5b46e39b4742593aa6fa62a1b69cc7ba404feeb6d40167d2e4c9f3",
  "USER_ACTIVITY_API_URL": "https://user-activity-gw.whykeyway.com",
  "NEXT_PUBLIC_AMPLITUDE_API_KEY": "efc35456fb28aea92631c2f458cd5249"
};

export default dotEnvVars;