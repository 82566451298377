type Middleware = (request: Request) => Promise<Request>;

const applyMiddlewares = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  middlewares: Middleware[] = []
) => {
  let request = new Request(input, init);

  for (const middleware of middlewares) {
    request = await middleware(request);
  }

  return fetch(request);
};

const createFetch =
  (middlewares: Middleware[]) =>
  (input: RequestInfo | URL, init?: RequestInit) =>
    applyMiddlewares(input, init, middlewares);

export {createFetch};
export type {Middleware};
