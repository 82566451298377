import {httpLink, splitLink} from "@trpc/client";
import {createTRPCNext} from "@trpc/next";

import environmentVariables from "@/utils/environment/environment-variables";

import type {AppRouter} from "../server/routers/_app";

import {createFetch} from "./fetch/middlewares/create-fetch";
import {withAuth0Token} from "./fetch/middlewares/with-auth0-token";

const apiUrl = (environmentVariables.BASE_PATH ?? "") + "/api/trpc";
const edgeApiUrl = (environmentVariables.BASE_PATH ?? "") + "/api/edge";
const edgeProcedures = [
  "getGeographicBoundaries",
  "getPropertyMarkers",
  "getPropertyClusters"
];
const trpc = createTRPCNext<AppRouter>({
  config: () => ({
    links: [
      splitLink({
        condition: op => edgeProcedures.includes(op.path),
        true: httpLink({
          url: edgeApiUrl,
          fetch: createFetch([withAuth0Token])
        }),
        false: httpLink({
          url: apiUrl,
          fetch: createFetch([withAuth0Token])
        })
      })
    ],
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false
        }
      }
    }
  }),
  ssr: false
});

const edgeTrpc = createTRPCNext<AppRouter>({
  config: () => ({
    links: [
      httpLink({
        url: edgeApiUrl,
        fetch: createFetch([withAuth0Token])
      })
    ],
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false
        }
      }
    }
  }),
  ssr: false
});

export {edgeTrpc, trpc};
