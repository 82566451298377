import * as withPropertyAnalyzerApiClient from "@unlockre/property-analyzer-api-tools/dist/property-analyzer-api-client";

import {auth0Client} from "@/utils/auth0";
import environmentVariables from "@/utils/environment/environment-variables";

const propertyAnalyzerApiClient = withPropertyAnalyzerApiClient.create(
  environmentVariables.PROPERTY_ANALYZER_API_URL,
  auth0Client
);

export {propertyAnalyzerApiClient};
