import "styled-components";

import {lightTheme} from "@unlockre/components-library/dist/theme-provider/theme/light-theme";

const applicationTheme = lightTheme;

type ApplicationTheme = typeof applicationTheme;

declare module "styled-components" {
  // eslint-disable-next-line
  interface DefaultTheme extends ApplicationTheme {}
}

export {applicationTheme};
export type {ApplicationTheme};
