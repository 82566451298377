import {datadogRum} from "@datadog/browser-rum";

import Application from "@/components/application";
import environmentVariables from "@/utils/environment/environment-variables";
import {trpc} from "@/utils/trpc";

datadogRum.init({
  applicationId: environmentVariables.DATADOG_APPLICATION_ID,
  clientToken: environmentVariables.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "property-sage",
  env: environmentVariables.APP_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackResources: true,
  defaultPrivacyLevel: "mask-user-input"
});

datadogRum.startSessionReplayRecording();
export default trpc.withTRPC(Application);
