import {SplitSdk} from "@splitsoftware/splitio-react";
import type SplitIO from "@splitsoftware/splitio-react/types/splitio/splitio";

import environmentVariables from "../environment/environment-variables";

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: environmentVariables.SPLIT_IO_BROWSER_API_KEY,
    key: "keysage"
  }
};

const splitIoFactory = SplitSdk(sdkConfig);

export default splitIoFactory;
