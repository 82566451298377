import type {BusinessType} from "@/domain/business-type";

type Route = "medical" | "multifamily";
type PropertyType = "MEDICAL_OFFICE" | "MULTIFAMILY";
const routePropertyTypePairs: Record<
  NonNullable<BusinessType>,
  {propertyType: PropertyType; route: Route}
> = {
  nnn: {
    route: "medical",
    propertyType: "MEDICAL_OFFICE"
  },
  mf: {
    route: "multifamily",
    propertyType: "MULTIFAMILY"
  }
};
const getRouteByBusinessType = (
  businessType: BusinessType
): {propertyType: PropertyType; route: Route} =>
  routePropertyTypePairs[businessType];

export {getRouteByBusinessType};
