import * as withOpenApiClient from "@unlockre/open-api-client/dist";
import type {OpenApiClientFrom} from "@unlockre/open-api-client/dist";

import type {paths} from "@/utils/api/property-search/property-search-api-schema";
import {auth0Client} from "@/utils/auth0";
import environmentVariables from "@/utils/environment/environment-variables";

type SearchApiClient = OpenApiClientFrom<paths>;

const propertySearchApiName = "propertySearch";

const propertySearchApiClient = withOpenApiClient.create<paths>({
  baseUrl: environmentVariables.PROPERTY_SEARCH_API_URL,
  use: [withOpenApiClient.createAuth0Middleware(auth0Client)]
});

const createPropertySearchApiClient = (baseUrl: string) =>
  withOpenApiClient.create<paths>({
    baseUrl,
    use: [withOpenApiClient.createAuth0Middleware(auth0Client)]
  });

export {
  propertySearchApiClient,
  propertySearchApiName,
  createPropertySearchApiClient
};

export type {SearchApiClient};
