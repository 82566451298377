import * as withOrganizationsApiClient from "@unlockre/organizations-api-tools/dist/organizations-api-client";

import {auth0Client} from "@/utils/auth0";
import environmentVariables from "@/utils/environment/environment-variables";

const organizationsApiClient = withOrganizationsApiClient.create(
  environmentVariables.ORGANIZATIONS_API_URL,
  auth0Client
);

export {organizationsApiClient};
