import * as withBuyBoxApiClient from "@unlockre/buy-box-api-tools/dist/buy-box-api-client";

import {auth0Client} from "@/utils/auth0";
import environmentVariables from "@/utils/environment/environment-variables";

const buyBoxApiClient = withBuyBoxApiClient.create(
  environmentVariables.BUY_BOX_API_URL,
  auth0Client
);

export {buyBoxApiClient};
